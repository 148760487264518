
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseReport from '@/components/reports-v2/BaseReport.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import accountx from '@/store/modules/accountx';
import moment from 'moment';
import InputText from 'primevue/inputtext';

@Component({
	components: {
		DataTable,
		Column,
		Button,
		Dialog,
		Toast,
    InputText,
	},
})
export default class Dealer extends BaseReport {

  public pathModal: boolean = false;

	public loginEmailsModal: boolean = false;

  public selectedPath: string = '';

	public selectedList: any[] = [];

	public keywords: string = '';

	public fields: any[] = [
		{ key: 'dealerId', label: 'Dealer ID' },
		{ key: 'dealerName', label: 'Dealer Name' },
		{ key: 'accountId', label: 'Company ID' },
		{ key: 'companyName', label: 'Company Name' },
		{ key: 'fdbPath', label: 'PDB Path/Name' },
		{ key: 'allAccessEmail', label: 'Login Emails' },
		{ key: 'dbRemark', label: 'Remarks' },
		{ key: 'token', label: 'Token' },
	];

	public items: any[] = [
		{
			dealerId: '1',
			dealerName: 'Dealer 1',
			accountId: '1',
			companyName: 'Company 1',
			fdbPath: 'asdasdad',
			allAccessEmail: [
				'asdasdasdadS',
				'askdaskdaskdasd',
				'sakdpoaskdpsakdaspdk',
				'asdasdasdadS',
				'askdaskdaskdasd',
				'sakdpoaskdpsakdaspdk',
				'asdasdasdadS',
				'askdaskdaskdasd',
				'sakdpoaskdpsakdaspdk',
				'asdasdasdadS',
				'askdaskdaskdasd',
				'sakdpoaskdpsakdaspdk',
			],
			dbRemark: 'sadoaskdop',
			token: 'asopdkaosp21321rofwoq',
		},
	];

	public get filterItems(): any[] {
		return this.items.filter(
			(item) =>
				(
            item.version +
            item.dealerName +
            item.companyName +
            item.dbRemark
				)
					.toLowerCase()
					.includes(this.keywords.toLowerCase()) ||
          item.allAccessEmail.some((email) => email.toLowerCase().includes(this.keywords.toLowerCase())),
		);
	}

  public showPath(x: any) {
    this.selectedPath = x;
    this.pathModal = true;
  }

	public showLoginEmails(x: any) {
		this.selectedList = x;
		this.loginEmailsModal = true;
	}

	public copy(x) {
		navigator.clipboard.writeText(x);
		this.$toast.add({
			severity: 'success',
			summary: 'Copied',
			detail: 'Copied to clipboard',
			life: 3000,
		});
	}

	public async mounted() {
		const arCustomers = await FilteredDatabase.ref('dashboardArCustomer').get();
		const arCustomerCards = await arCustomers.getCards();

		if (accountx.currentAccountId === 'HsJ2Zj1jFkQVkxOKmxvk') {
			this.items = arCustomerCards;
			this.items.forEach((item) => {
				// remove last comma from string//
				item.allAccessEmail = item.allAccessEmail.slice(0, -1);
				item.allAccessEmail = item.allAccessEmail.split(',');
        // transform start and end date to readable format//
    item.startDate = moment(item.startDate).format('DD/MM/YYYY');
    item.endDate = moment(item.endDate).format('DD/MM/YYYY');
			});
		} else {
			this.$router.push('/');
		}
	}
}
